
// Forgot password page is to get email from user to validate account and send reset link.

import { defineComponent, reactive, toRefs, ref } from "vue";
import FormGenerator from "@/components/forms/form-generator.vue"; // @ is an alias to /src
import { emailValidator, requiredValidator } from "../../validators";
import ApiResources from "../../rest-resource";

export default defineComponent({
  name: "ForgotPassword",
  components: {
    FormGenerator,
  },
  setup: () => {
    const validatedForm: any = ref({});
    const state = reactive({
      schema: [
        {
          fieldType: "InputField",
          placeholder: "Email*",
          label: "",
          name: "email",
          type: "email",
          value: "",
          class: "form-group mb-2 mt-3",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "Email is required" },
            { validator: emailValidator, message: "Please enter valid email" },
          ],
        },
      ],
      submitButton: {
        class: "btn submit-btn float-center",
        value: "Submit",
        loading: "Submitting...",
      },
    });

    return { ...toRefs(state), validatedForm };
  },
  methods: {
    async validForm(data: any) {
      const proxy1 = new Proxy(data, {});
      const dataValues = JSON.parse(JSON.stringify(proxy1));
      dataValues.forEach((element: any) => {
        this.validatedForm[element.name] = element.value;
      });
      try {
        let resourceEndPoint =
          ApiResources.AuthResource.wrap("/reset-password");
        await resourceEndPoint.post(this.validatedForm);
        this.displayMessage("Email link sent successfully", "white");
        this.callRestForm();
      } catch (err: any) {
        this.callRestForm(false);
        this.displayMessage(err.response.data.message, "danger");
      }
    },
    callRestForm(reset = true) {
      (this.$refs["generatedform"] as any).resetForm(reset);
    },
  },
});
